import styled from 'styled-components';
import theme, { appTheme } from '../../theme';

export const Container = styled.section`
  width: 100%;
`;

export const Content = styled.section`
  flex: 1;
  background: ${theme.colors.cardB1};
  padding: 1%;
`;

export const Card = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background: linear-gradient(#088CD2, #193D84);
  width: 100%;
  border-radius: 15px;
  padding-bottom: 16px;
`;

export const Title = styled.h3`
  text-transform: uppercase;
  color: white;
`;

export const Info = styled.p`
  font-size: 14px; 
  padding-top: 13px;
  color: white;
  font-weight: 500;
`;

export const Distance = styled.p`
  color: white;
  font-size: 13px;
`;

export const Medal = styled.img`
  width: 155px;
  height: 160px;
`;

export const InfoContainer = styled.div`
  padding-left: 150px;
  padding-right: 35px;
  width: 100%;

  @media (max-width: 667px) {
    padding-left: 0px;
    width: 85%;
  }
`;

export const TextContainer = styled.div`
  padding-top: 33px;
  width: 85%;
`;

export const FlexContainer = styled.div`
  display: flex;
`;

export const Image = styled.img`
  width: 160px;
  height: 160px;
`;

export const GoalContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 45px;
  width: 100%;
  align-items: center;
`;

export const GoalTextContainer = styled.div`
  padding-top: 15px;
  width: 85%;
`;

export const GoalWeeksContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 85%;
  align-items: center;
  margin-top: 28px;
`;

export const WeeksSelect = styled.select`
  padding: 8px;
  align-items: center;
  width: 60%;
  color: white;
  background-color: transparent;
  cursor: pointer;
  font-weight: bold;
  font-family: 'Lato',sans-serif;
  font-size: 14px;
  text-transform: uppercase;
  border: none;
  text-align: center;

  & > option {
    color: black;
    font-weight: 400;
    text-transform: initial;
    text-align: start;
  }

  @media (max-width: 667px) {
    font-size: 12px;
    width: 90%;
  }
  
`;

export const Line = styled.div`
  width: 100%;
  height: 1px;
  background-color: ${theme.colors.primary};
`;

export const DateCard = styled.div`
  width: 85%;
  border-radius: 15px;
  background-color: ${theme.colors.primary};
  padding: 13px;
  margin-top: 36px;
`;

export const DateContainer = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 13px;
`;

export const DateLabel = styled.p`
  color: white;
  font-size: 14px;
`;

export const DateValue = styled.p`
  color: ${theme.colors.notifications};
  font-size: 14px;
`;

export const MapContainer = styled.div`
  width: 100%;
  height: 410px;
  background-color: ${appTheme.cardB2};
`;

export const ProgressContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 85%;
`;

export const ProgressInfoWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;
  align-items: center;
  margin-top: 22px;
`;

export const ProgressInfoContainer = styled.div<{full?: boolean}>`
  display: flex;
  width: ${({ full }) => (full ? 90 : 70)}%;
  justify-content: space-between;
  align-items: center;

  @media (max-width: 667px) {
    width: 85%;
  }
`;

export const LabelContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const ProgressLabel = styled.span<{color?: string}>`
  color: ${({ color }) => color || theme.colors.text};
  text-transform: uppercase;
  font-size: 13px;
  font-weight: bold;
`;

export const ProgressValue = styled.span`
  font-size: 22px;
  color: white;
  text-transform: uppercase;
  font-weight: bold;

  @media (max-width: 667px) {
    font-size: 18px;
  }
`;

export const RegisterButton = styled.button<{color?: string}>`
  margin-top: 33px;
  margin-bottom: 33px;
  background-color: ${({ color }) => (color || 'transparent')};
  border-radius: 5px;
  align-items: center;
  border: '1px';
  border-style: solid;
  border-color: ${({ color }) => (color || theme.colors.title)};
  align-self :center;
  padding: 10px;
  cursor: pointer;
  &:hover {
      opacity: 0.7;
    }
`;

export const RegisterButtonText = styled.span<{color?: string}>`
  color: ${({ color }) => (color || theme.colors.title)};
  font-weight: bold;
  font-size: 15px;
`;

export const FinishedContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 80%;
  padding-top: 33px;
`;

export const FinishedTitle = styled.h3`
  text-transform: uppercase;
  color: white;
  margin-bottom: 23px;
  text-align: center;
`;

export const MedalRequestContainer = styled.div`
  margin-top: 13px;
  margin-bottom: 13px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;
`;

export const MedalRequestInfo = styled.p`
  font-size: 16px; 
  padding-top: 13px;
  color: white;
  font-weight: 600;
`;

export const LoadingContainer = styled.div`
  width: 100%;
  justify-content: center;
  align-items: center;
`;
