import React from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Card, Container, Title, Info,
  Button, Image,
  ButtonContainer,
  LinkButton,
} from './styles';

import Integration from '../../assets/integration/integration.png';

interface Props {
  action: (value: boolean) => void
}

export default function IntegrationCard({ action }: Props) {
  const navigate = useNavigate();

  return (
    <Card>
      <Image src={Integration} />
      <Container>
        <Title>CURTA AINDA MAIS SEU DESAFIO REGISTRANDO SUAS ATIVIDADES DE FORMA AUTOMÁTICA</Title>
        <Info>Conecte seu relógio ou Strava para que suas distâncias sejam somadas de forma automática.</Info>
        <ButtonContainer>
          <Button onClick={() => navigate('/profile/edit?field=integracoes')}>Fazer integração</Button>
          <LinkButton onClick={() => action(false)}>Agora não</LinkButton>
        </ButtonContainer>
      </Container>
    </Card>
  );
}
