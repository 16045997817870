import React from 'react';
import {
  InnerContainer, Input, Label, OuterContainer, PerKMText, PerKMContainer,
} from './styles';

export interface IPaceInputProps {
  valueInSeconds: number | undefined;
  onChange: (seconds: number) => void;
}

function PaceInput({ valueInSeconds, onChange }: IPaceInputProps) {
  const [innerValue, setInnerValue] = React.useState<string | undefined>(undefined);

  React.useEffect(() => {
    if (valueInSeconds === undefined) {
      setInnerValue(undefined);
      return;
    }

    const valueToDisplay = (valueInSeconds * 60);
    setInnerValue(valueToDisplay.toString());
  }, [valueInSeconds]);

  React.useEffect(() => {
    if (innerValue === undefined) return;

    const sanitizedValue = innerValue.replace(',', '.');
    const parsedValue = parseFloat(sanitizedValue) / 60;
    onChange(parsedValue);
  }, [innerValue]);

  function handlePaceChange(event: React.ChangeEvent<HTMLInputElement>) {
    const sanitizedValue = event.target.value.replace(',', '.');
    const parsedValue = parseFloat(sanitizedValue);
    if (parsedValue > 999) return;

    setInnerValue(sanitizedValue);
  }

  return (
    <OuterContainer>
      <Label>Pace</Label>
      <InnerContainer>
        <Input
          type="number"
          value={innerValue || ''}
          onChange={handlePaceChange}
          placeholder="00,00"
        />
        <PerKMContainer>
          <PerKMText>
            min/km
          </PerKMText>
        </PerKMContainer>
      </InnerContainer>
    </OuterContainer>
  );
}

export default PaceInput;
