import React from 'react';
import { MdModeEdit } from 'react-icons/md';
import {
  Container, Distance, Header, Label, Medal, HeaderContainer,
  InfoContainer,
  EditContainer,
  EditText,
} from './styles';

import SerraGauchaMedal from '../../assets/challenges/medal-serra-gaucha-long.png';
import { ChallengeStatesTypes } from '../../pages/ChallengePage';

interface Props {
  edit: () => void
  status: ChallengeStatesTypes
}

export default function ChallengeHeader({ edit, status }: Props) {
  return (
    <Container>
      <Medal src={SerraGauchaMedal} />
      <HeaderContainer>
        <Header>desafio serra gaúcha</Header>
        <InfoContainer>
          <div>
            <Label>Distância</Label>
            <Distance>135km</Distance>
          </div>
          {status === 'IN_PROGRESS' && (
            <EditContainer onClick={edit}>
              <MdModeEdit color="white" />
              <EditText>Editar meta de tempo</EditText>
            </EditContainer>
          )}
        </InfoContainer>
      </HeaderContainer>
    </Container>
  );
}
