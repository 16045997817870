import React from 'react';
import {
  Info, InfoContainer, TextContainer, Title,
} from './styles';
import ChallengeSubscriptionCard from '../../components/ChallengeSubscriptionCard';

interface Props {
  action: () => void
}

export default function ChallengeSubscription({ action }: Props) {
  return (
    <>
      <InfoContainer>
        <Info>Se aventure por meio de cenários incríveis da Serra Gaúcha, saindo do Vale dos Vinhedos e chegando na icônica cidade de Gramado. Parte de sua inscrição é destinada às vítimas das enchentes no Rio Grande do Sul.</Info>
      </InfoContainer>
      <TextContainer>
        <Title>COMO COMPLETAR O DESAFIO?</Title>
        <Info>O Desafio vai sendo concluído a partir da soma das distâncias de suas atividades (corridas, caminhadas, pedaladas, natação, etc.).</Info>
        <Info>Você pode registrar as atividades direto na plataforma ou de forma automática, pelas integrações com relógios e outros aplicativos.</Info>
      </TextContainer>
      <ChallengeSubscriptionCard action={action} />
    </>
  );
}
