import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Container, Content } from './styles';
import BackArrow from '../../components/BackArrow';
import ChallengeCardList from '../../components/ChallengeCardList';

import SerraGaucha from '../../assets/challenges/challenge-serra-gaucha.png';
import SerraGauchaMedal from '../../assets/challenges/medal-serra-gaucha.png';

export default function Challenges() {
  const navigate = useNavigate();

  const challenges = [{
    title: 'desafio serra gaúcha',
    info: 'Se aventure por meio de cenários incríveis da Serra Gaúcha, saindo do Vale dos Vinhedos e chegando na icônica cidade de Gramado. Parte de sua inscrição é destinada às vítimas das enchentes no Rio Grande do Sul.',
    image: SerraGaucha,
    medal: SerraGauchaMedal,
    distance: 135,
  }];

  return (
    <Container>
      <BackArrow text="Desafios" bigger backTo="/club" />
      <Content>
        {challenges.map(({
          image, medal, title, info, distance,
        }, index) => (
          <ChallengeCardList
            key={index}
            action={() => navigate('serra-gaucha')}
            image={image}
            medal={medal}
            title={title}
            info={info}
            distance={distance}
          />
        ))}
      </Content>
    </Container>
  );
}
