import axios from 'axios';

const DEV = process.env.NODE_ENV === 'development';

const BASE_URL = DEV ? 'http://localhost:3333' : 'https://api.corridaperfeita.com';

export const STRAVA_URL = 'https://www.strava.com/oauth/authorize';

export const NEW_API_BASE_URL = DEV ? 'http://localhost:3002' : 'https://v2.api.corridaperfeita.com';

export const LOGIN_API_URL = DEV ? 'http://localhost:3000' : 'https://auth.api.corridaperfeita.com';

const api = axios.create({ baseURL: BASE_URL });

export const api2 = axios.create({ baseURL: NEW_API_BASE_URL });

export const LoginApi = axios.create({ baseURL: LOGIN_API_URL });

export default api;
