import React from 'react';
import { MdDone } from 'react-icons/md';
import {
  Container, Content, ContentContainer, Header, Info, Price, Title,
} from './styles';
import theme from '../../theme';
import ModalButton from '../ModalButton';

interface Props {
  action: () => void
}

export default function ChallengeSubscriptionCard({ action }: Props) {
  const redirectToPayment = () => {
    action();
  };

  return (
    <Container>
      <Header>
        <Title>Inscrição + Medalha</Title>
        <Price>R$ 99,99</Price>
        <Info>+ frete conforme seu CEP</Info>
      </Header>
      <ContentContainer>
        <MdDone size={18} color={theme.colors.graph} />
        <Content>Inscrição no desafio</Content>
      </ContentContainer>
      <ContentContainer>
        <MdDone size={18} color={theme.colors.graph} />
        <Content>Medalha de finisher exclusiva</Content>
      </ContentContainer>
      <ContentContainer>
        <MdDone size={18} color={theme.colors.graph} />
        <Content>Acompanhamento do progresso</Content>
      </ContentContainer>
      <ContentContainer>
        <MdDone size={18} color={theme.colors.graph} />
        <Content>Visualização de ruas da sua localização virtual</Content>
      </ContentContainer>
      <ContentContainer>
        <MdDone size={18} color={theme.colors.graph} />
        <Content>Medalha enviada após a conclusão</Content>
      </ContentContainer>

      <ModalButton title="INSCREVA-SE AGORA" onPress={redirectToPayment} style={{ padding: 8, margin: 23 }} />
    </Container>
  );
}
