import styled from 'styled-components';

import { appTheme } from '../../theme';

export const Label = styled.div`
  color: ${appTheme.title};
  margin-bottom: 5px;
  margin-left: 5px;
  font-weight: 700;
  font-size: 12px;
  line-height: 14px;
  text-transform: uppercase;

  @media (max-width: 667px) {
    font-size: 10px;
  }
`;

export const Container = styled.div`
  margin: 0 5px;
  flex: 1;

  svg {
    position: absolute;
    right: 5px;
    transform: translate(0, 30%);
    cursor: pointer;

    height: 15px;
    width: 15px;

    user-select: none;
  }
`;

const baseInputStyle = `
  border-radius: 5px;
  background-color: ${appTheme.cardB3};
  width: 100%;
  color: ${appTheme.title};
  padding: 0px;
  padding-left: 10px;
  padding-right: 5px;
  border: none;
  height: 25px;
  font-size: 12px;

  @media (max-width: 667px) {
    height: 20px;
    font-size: 10px;
  }
`;

export const InputContainer = styled.div`
  position: relative;
  flex: 1;

  input[type=number]::-webkit-inner-spin-button,
  input[type=number]::-webkit-outer-spin-button {
      -webkit-appearance: none;
      margin: 0;
  }

  input[type=number]::-moz-number-spin-box {
      display: none;
  }

  input[type=number]::-ms-inner-spin-button {
      display: none;
  }

  input {
    ${baseInputStyle}
  }
`;

export const Input = styled.input`
  ::-webkit-calendar-picker-indicator {
    background: transparent;
    bottom: 0;
    color: transparent;
    cursor: pointer;
    height: auto;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    width: auto;
  }

  ${baseInputStyle}
`;

export const Select = styled.select`
  ${baseInputStyle}
`;

export const TextArea = styled.textarea`
  ${baseInputStyle}
  min-height: 93px;
  resize: none;
`;

export const ErrorMessage = styled.div`
  color: red;
  margin: 5px;
  font-size: 13px;
`;
