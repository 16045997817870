import React from 'react';
import { Routes as Switch, Route, Navigate } from 'react-router-dom';
import Club from '../pages/Club';
import ExerciseList from '../pages/ExerciseList';
import Help from '../pages/Help';
import Home from '../pages/Home';
import Login from '../pages/Login';
import Progress from '../pages/Progress';
import Training from '../pages/Training';
import ExerciseTrainingCover from '../pages/ExerciseCover';
import Profile from '../pages/Profile';
import PrivateRoute from './PrivateRoute';
import ExerciseExecution from '../pages/ExerciseExecution';
import ProfileEdit from '../pages/ProfileEdit';
import TelegramGroups from '../components/TelegramGroups';
import LiveClasses from '../components/LiveClasses';
import LiveClassSchedule from '../components/LiveClassSchedule';
import RecordedClasses from '../components/RecordedClasses';
import RecordedClassesExecution from '../components/RecordedClassExecution';
import LiveClassDetails from '../components/LiveClassDetails';
import NutritionGuide from '../components/NutritionGuide';
import CoachAttendance from '../pages/Help/CoachAttendance';
import AskQuestions from '../components/AskQuestions';
import Cashback from '../components/Cashback';
import Store from '../components/Store';
import FinancialService from '../pages/Help/FinancialService';
import TecnicalSuport from '../pages/Help/TecnicalSuport';
import ActivityList from '../pages/ActivityList';
import ClassesList from '../components/ClassesList';
import ClassesCover from '../components/ClassesCover';
import ClassExecution from '../components/ClassesExecution';
import ActivityDetail from '../components/ActivityDetail';
import NewZone from '../pages/NewZone';
import ZoneList from '../components/ZoneList';
import Zones from '../components/Zones';
import PasswordRecovery from '../pages/PasswordRecovery';
import RequestTrainingPlan from '../components/RequestTrainingPlan';
import ResetPassword from '../pages/ResetPassword';
import RacingDiscount from '../components/RacingDiscount';
import Notifications from '../pages/Profile/Notification';
import IndicationCentral from '../pages/IndicationCentral';
import PointsDetail from '../pages/PointsDetail';
import RedeemProduct from '../pages/RedeemProduct';
import RedeemProductProvider from '../pages/RedeemProduct/RedeemProductProvider';
import PublicRoute from './PublicRoute';
import RaceCalendar from '../pages/RaceCalendar';
import StateCalendar from '../pages/RaceCalendar/StateCalendar';
import RaceCalendarProvider from '../pages/RaceCalendar/Context/RaceCalendarProvider';
import LoginRedirect from '../pages/LoginRedirect';
import CalculatorsProvider from '../pages/Calculators/Context/CalculatorsProvider';
import CalculatorsList from '../pages/CalculatorsList';
import Prediction from '../pages/Prediction';
import PaceCalculator from '../pages/Calculators';
import MonthRetrospective from '../pages/MonthRetrospective';
import Benefits from '../pages/Benefits';
import Challenges from '../pages/Challenges';
import ChallengePage from '../pages/ChallengePage';

export default function Routes() {
  return (
    <Switch>
      <Route path="/" element={<Navigate to="/home" />} />
      <Route path="/dashboard" element={<Navigate to="/home" />} />
      <Route path="/home" element={<PrivateRoute />}>
        <Route path="/home" element={<Home />} />
      </Route>

      <Route path="/login" element={<Login />} />
      <Route path="/login/redirect" element={<LoginRedirect />} />

      <Route path="/corridaderua" element={<PublicRoute displayNavbar={false} />}>
        <Route
          index
          element={
            (
              <RaceCalendarProvider>
                <RaceCalendar />
              </RaceCalendarProvider>

            )
          }
        />
      </Route>
      <Route path="/corridaderua/:state/" element={<PublicRoute displayNavbar={false} />}>
        <Route
          index
          element={
            (
              <RaceCalendarProvider>
                <StateCalendar />
              </RaceCalendarProvider>

            )
          }
        />
      </Route>
      <Route path="/corridaderua/:state/:coupon" element={<PublicRoute displayNavbar={false} />}>
        <Route
          index
          element={
            (
              <RaceCalendarProvider>
                <StateCalendar />
              </RaceCalendarProvider>

            )
          }
        />
      </Route>
      <Route path="/corridaderua/:state/:city" element={<PublicRoute displayNavbar={false} />}>
        <Route
          index
          element={
            (
              <RaceCalendarProvider>
                <StateCalendar />
              </RaceCalendarProvider>

            )
          }
        />
      </Route>

      <Route path="/training" element={<PrivateRoute />}>
        <Route path="/training" element={<Training />} />
      </Route>
      <Route path="/meu-treino" element={<Navigate to="/training" />} />

      <Route path="/progress" element={<PrivateRoute />}>
        <Route path="/progress" element={<Progress />} />
      </Route>

      <Route path="/retrospective" element={<PrivateRoute />}>
        <Route path="/retrospective" element={<MonthRetrospective />} />
      </Route>

      <Route path="/club" element={<PrivateRoute />}>
        <Route path="/club" element={<Club />} />
      </Route>

      <Route path="/exerciseList/:type" element={<PrivateRoute />}>
        <Route path="/exerciseList/:type" element={<ExerciseList />} />
      </Route>

      <Route path="/exerciseCover/:type/:id" element={<PrivateRoute />}>
        <Route path="/exerciseCover/:type/:id" element={<ExerciseTrainingCover />} />
      </Route>

      <Route path="/exerciseExecution" element={<PrivateRoute displayNavbar={false} />}>
        <Route path="/exerciseExecution" element={<ExerciseExecution />} />
      </Route>

      <Route path="/help" element={<PrivateRoute />}>
        <Route path="/help" element={<Help />} />
      </Route>

      <Route path="/perfil" element={<Navigate to="/profile" />} />

      <Route path="/profile" element={<PrivateRoute displayNavbar={false} />}>
        <Route path="/profile" element={<Profile />} />
      </Route>

      <Route path="/RedeemProduct" element={<PrivateRoute displayNavbar={false} />}>
        <Route
          index
          element={
            (
              <RedeemProductProvider>
                <RedeemProduct />
              </RedeemProductProvider>

            )
          }
        />
      </Route>

      <Route path="/indication" element={<PrivateRoute displayNavbar={false} />}>
        <Route
          index
          element={
            (
              <RedeemProductProvider>
                <IndicationCentral />
              </RedeemProductProvider>

            )
          }
        />
      </Route>

      <Route path="/notifications" element={<PrivateRoute displayNavbar={false} />}>
        <Route path="/notifications" element={<Notifications />} />
      </Route>

      <Route path="/indication/points" element={<PrivateRoute displayNavbar={false} />}>
        <Route path="/indication/points" element={<PointsDetail />} />
      </Route>

      <Route path="/profile/edit" element={<PrivateRoute displayNavbar={false} />}>
        <Route path="/profile/edit" element={<ProfileEdit />} />
      </Route>

      <Route path="/zones" element={<PrivateRoute displayNavbar={false} />}>
        <Route path="/zones" element={<Zones />} />
      </Route>

      <Route path="/zonelist" element={<PrivateRoute displayNavbar={false} />}>
        <Route path="/zonelist" element={<ZoneList />} />
      </Route>

      <Route path="/howtozones" element={<PrivateRoute displayNavbar={false} />}>
        <Route path="/howtozones" element={<NewZone />} />
      </Route>

      <Route path="/club/exclusive-classes" element={<PrivateRoute displayNavbar />}>
        <Route path="/club/exclusive-classes" element={<ClassesList />} />
      </Route>
      <Route path="/video-aulas" element={<Navigate to="/club/exclusive-classes" />} />

      <Route path="/club/ClassesCover" element={<PrivateRoute displayNavbar={false} />}>
        <Route path="/club/ClassesCover" element={<ClassesCover />} />
      </Route>

      <Route path="/club/ClassExecution" element={<PrivateRoute displayNavbar={false} />}>
        <Route path="/club/ClassExecution" element={<ClassExecution />} />
      </Route>

      <Route path="/club/CoachAttendance" element={<PrivateRoute displayNavbar={false} />}>
        <Route path="/club/CoachAttendance" element={<CoachAttendance />} />
      </Route>

      <Route path="/club/desafios" element={<PrivateRoute />}>
        <Route path="/club/desafios" element={<Challenges />} />
      </Route>

      <Route path="/club/desafios/serra-gaucha" element={<PrivateRoute />}>
        <Route path="/club/desafios/serra-gaucha" element={<ChallengePage />} />
      </Route>

      <Route path="/club/desafios/execution" element={<PrivateRoute />}>
        <Route path="/club/desafios/execution" element={<ChallengePage />} />
      </Route>

      <Route path="/club/telegram-groups" element={<PrivateRoute displayNavbar={false} />}>
        {/* <Route path="/club/telegram-groups" element={<PrivateRoute displayNavbar={false} />}> */}
        <Route path="/club/telegram-groups" element={<TelegramGroups bigger />} />
      </Route>
      <Route path="/grupos-telegram" element={<Navigate to="/club/telegram-groups" />} />
      <Route path="/grupos-whatsapp" element={<Navigate to="/club/telegram-groups" />} />
      <Route path="/club/NutritionGuide" element={<PrivateRoute displayNavbar={false} />}>
        <Route path="/club/NutritionGuide" element={<NutritionGuide bigger />} />
      </Route>

      <Route path="/club/live-classes" element={<PrivateRoute displayNavbar={false} />}>
        <Route path="/club/live-classes" element={<LiveClasses />} />
      </Route>

      <Route path="/club/live-classes/schedule" element={<PrivateRoute displayNavbar={false} />}>
        <Route path="/club/live-classes/schedule" element={<LiveClassSchedule />} />
      </Route>
      <Route path="/agenda-lives" element={<Navigate to="/club/live-classes/schedule" />} />

      <Route path="/club/RecordedClasses" element={<PrivateRoute displayNavbar={false} />}>
        <Route path="/club/RecordedClasses" element={<RecordedClasses />} />
      </Route>
      <Route path="/gravacoes-anteriores" element={<Navigate to="/club/RecordedClasses" />} />

      <Route path="/club/RecordedClassesExecution" element={<PrivateRoute displayNavbar={false} />}>
        <Route path="/club/RecordedClassesExecution" element={<RecordedClassesExecution />} />
      </Route>

      <Route path="/club/live-classes/details" element={<PrivateRoute displayNavbar={false} />}>
        <Route path="/club/live-classes/details" element={<LiveClassDetails />} />
      </Route>
      <Route path="/club/ask-questions" element={<PrivateRoute displayNavbar={false} />}>
        <Route path="/club/ask-questions" element={<AskQuestions />} />
      </Route>
      <Route path="/club/cashback" element={<PrivateRoute displayNavbar={false} />}>
        <Route path="/club/cashback" element={<Cashback />} />
      </Route>
      <Route path="/club/store" element={<PrivateRoute displayNavbar={false} />}>
        <Route path="/club/store" element={<Store />} />
      </Route>
      <Route path="/club/beneficios" element={<PrivateRoute displayNavbar={false} />}>
        <Route path="/club/beneficios" element={<Benefits />} />
      </Route>
      <Route path="/club/racing-discount" element={<PrivateRoute displayNavbar={false} />}>
        <Route path="/club/racing-discount" element={<RacingDiscount />} />
      </Route>

      <Route path="/club/calculadoras" element={<PublicRoute displayNavbar={false} />}>
        <Route path="/club/calculadoras" element={<CalculatorsList />} />
      </Route>

      <Route path="/calculadora-de-ritmo" element={<PublicRoute displayNavbar={false} />}>
        <Route
          index
          element={
            (
              <CalculatorsProvider>
                <PaceCalculator showNav />
              </CalculatorsProvider>

            )
          }
        />
      </Route>

      <Route path="/previsao-de-tempos" element={<PublicRoute displayNavbar={false} />}>
        <Route
          index
          element={
            <Prediction />
          }
        />
      </Route>

      <Route path="/calculadora-embedded" element={<PublicRoute displayNavbar={false} displayHeader={false} />}>
        <Route
          index
          element={
            (
              <CalculatorsProvider>
                <PaceCalculator showNav={false} />
              </CalculatorsProvider>
            )
          }
        />
      </Route>

      <Route path="/calculadora-de-ritmo/:activeCalculator" element={<PublicRoute displayNavbar={false} />}>
        <Route
          index
          element={
            (
              <CalculatorsProvider>
                <PaceCalculator showNav />
              </CalculatorsProvider>
            )
          }
        />
      </Route>

      <Route path="/help/financial-service" element={<PrivateRoute displayNavbar={false} />}>
        <Route path="/help/financial-service" element={<FinancialService />} />
      </Route>
      <Route path="/help/tecnical-suport" element={<PrivateRoute displayNavbar={false} />}>
        <Route path="/help/tecnical-suport" element={<TecnicalSuport />} />
      </Route>
      <Route path="/help/CoachAttendance" element={<PrivateRoute displayNavbar={false} />}>
        <Route path="/help/CoachAttendance" element={<CoachAttendance />} />
      </Route>
      <Route path="/activity-list" element={<PrivateRoute displayNavbar={false} />}>
        <Route path="/activity-list" element={<ActivityList />} />
      </Route>
      <Route path="/activity-detail" element={<PrivateRoute displayNavbar={false} />}>
        <Route path="/activity-detail" element={<ActivityDetail />} />
      </Route>
      <Route path="/solicitar-recuperar-senha" element={<PasswordRecovery />} />
      <Route path="/request" element={<PrivateRoute displayNavbar={false} />}>
        <Route path="/request" element={<RequestTrainingPlan />} />
      </Route>

      <Route path="/recuperar-senha" element={<ResetPassword />} />

      <Route path="*" element={<h1>404 - Página não encontrada</h1>} />
    </Switch>
  );
}
