/* eslint-disable no-mixed-operators */
import React, {
  useEffect, useState, useRef, useCallback,
} from 'react';
import ContentLoader from 'react-content-loader';
import {
  MdShare, MdEdit, MdPerson,
  MdOutlineClose,
} from 'react-icons/md';
import { FaTrash } from 'react-icons/fa';
import { GoogleMap, Polyline, useJsApiLoader } from '@react-google-maps/api';
import {
  addDays, format, isWithinInterval, subDays,
} from 'date-fns';
import { ptBR } from 'date-fns/locale';
import { useDispatch, useSelector } from 'react-redux';
import toast from 'react-hot-toast';

// @ts-ignore
import { Activity, refreshActivities, setCurrentEditActivity } from '../../store/Activities.store';
import { RootState } from '../../store';

import api, { api2 } from '../../services/api';
import { exportAsImage, formatDuration, getGenre } from '../../utils';

import {
  ActivityContainer,
  ActivityTitle,
  Avatar,
  AvatarContainer,
  Container,
  DateText,
  HeaderContainer,
  IconButton,
  IconsContainer,
  InfoContainer,
  InfoItem,
  InfoText,
  MapContainer,
  Separator,
  UserContainer,
  UserName,
  TrainingContainer,
  TrainingLabel,
  AssociationContainer,
  AssociationLabel,
  OutContainer,
  DropdownContainer,
  AnalysisButton,
  AnalysisTextButton,
  FlexContainer,
  AttachShoesInfoButton,
  DetachShoesInfoButton,
  DetachShoesInfoButtonTitle,
  ExportContainer,
  ExportButton,
  ExportButtonTitle,
} from './styles';
import theme, { appTheme } from '../../theme';

import RemoveActivityModal from './RemoveActivityModal';
import DisassociateModal from './DisassociateModal';
import AnalysisModal, { IAnalysisData } from './AnalysisModal';
import SharingActivityModal from './SharingActivityModal';
import RegisterActivity from '../RegisterActivity';
import TrainingDetailContainer from './TrainingDetailContainer';

import Loader from '../Loader';
import BackArrow from '../BackArrow';
import SelectInput from '../SelectInput';
import mapStyle from './mapStyle';
import AttachShoesModal from '../AttachShoesModal';
import { updateProfileProps } from '../../store/Auth.store';
import DeactivateControlKmsModal from '../DeactivateControlKmsModal';
import DetachShoesModal from '../DetachShoesModal';
import GPXExportIcon from '../../assets/gpx-file-export.svg';
import TCXExportIcon from '../../assets/tcx-file-export.svg';

interface IAttachShoesPayload {
  activity_id?: number;
  barefoot?: boolean;
}

function ActivityDetail() {
  const { isLoaded: mapIsLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API || '',
  });

  const dispatch = useDispatch();

  const auth = useSelector((state: RootState) => state.auth);
  const { user } = auth;

  const { strava_config } = useSelector((state: RootState) => state.integration);

  const { currentEditActivity: currentActivity } = useSelector((state: RootState) => state.activities);
  // const permissions = useSelector((state: RootState) => state.permissions.items);

  function setCurrentActivity(newActivity: Activity) {
    dispatch(setCurrentEditActivity(newActivity) as any);
  }

  const scrollViewRef = useRef<HTMLDivElement>(null);
  const mapRef = useRef(null);

  const [coords, setCoords] = useState<any>(null);
  const [center, setCenter] = useState<any>(null);
  const [loadingCoords, setLoadingCoords] = useState<boolean>(false);
  const [removeModal, setRemoveModal] = useState<boolean>(false);
  const [editModal, setEditModal] = useState<boolean>(false);
  const [disassociateModal, setDisassociateModal] = useState<boolean>(false);
  const [sharingModal, setSharingModal] = useState<boolean>(false);
  const [mapImage, setMapImage] = useState<string | null>(null);
  const [trainingToLink, setTrainingToLink] = useState<number | null>(null);
  const [trainingOption, setTrainingOptions] = useState<{ label: string, value: number }[]>([]);
  const [training, setTraining] = useState<any>(null);
  const [trainingLoading, setTrainingLoading] = useState<boolean>(false);
  const [loadingAnalysisData, setLoadingAnalysisData] = useState<boolean>(false);
  const [analysisData, setAnalysisData] = useState<IAnalysisData | null>(null);
  const [analysisModal, setAnalysisModal] = useState<boolean>(false);
  const [showAttachShoesModal, setShowAttachShoesModal] = useState<boolean>(false);
  const [showDetachShoesModal, setShowDetachShoesModal] = useState<boolean>(false);
  const [detachShoesModalLoading, setDetachShoesModalLoading] = useState<boolean>(false);
  const [showDeactivateControlKmsModal, setShowDeactivateControlKmsModal] = useState<boolean>(false);
  const [deactivateControlKmsModalLoading, setDeactivateControlKmsModalLoading] = useState<boolean>(false);
  const [exportLoading, setExportLoading] = useState<boolean>(false);

  const date = format(new Date(currentActivity?.start_date || ''), "dd MMM yyyy 'às' HH:mm", { locale: ptBR });

  const isRun = ['Run', 'VirtualRun', 'Walk'].some((t) => t === currentActivity?.type);

  const findCenter = (markers: any) => {
    const lats = markers.map((m: any) => m.latitude);
    const lngs = markers.map((m: any) => m.longitude);
    setCenter({
      latitude: (Math.min(...lats) + Math.max(...lats)) / 2,
      longitude: (Math.min(...lngs) + Math.max(...lngs)) / 2,
    });
  };

  const loadCoords = async () => {
    setLoadingCoords(true);
    if (currentActivity?.id && currentActivity?.summary_polyline) {
      await api.get(`/user_activities/coordinates/${currentActivity?.id}`)
        .then((response) => {
          setCoords(response.data);
          findCenter(response.data);
          setLoadingCoords(false);
        })
        .catch(() => {
          setLoadingCoords(false);
        });
    }
    setLoadingCoords(false);
  };

  const refresh = (reloadPage = true) => {
    dispatch(refreshActivities(user.id) as any);
    if (reloadPage) window.location.reload();
  };

  const removeCallback = () => {
    refresh();
  };

  const disassociateCallback = (updatedActivity: Activity) => {
    setCurrentActivity(updatedActivity);
    setTraining(null);
    setTrainingToLink(null);
    refresh();
  };

  const scroll = () => {
    if (scrollViewRef.current) scrollViewRef.current.scrollTo({ top: 999 });
  };

  const capture = async () => {
    if (coords) {
      setMapImage(await exportAsImage(mapRef?.current as any));
    }
  };

  const openSharingModal = () => {
    capture();
    setSharingModal(true);
  };

  const loadLaps = async () => {
    setLoadingAnalysisData(true);
    if (currentActivity?.id && currentActivity?.type === 'Run') {
      await api.get(`/user_activities/laps/${currentActivity?.id}`)
        .then((response) => {
          if (response.data.laps.length > 1) {
            setAnalysisData(response.data);
          }
          setLoadingAnalysisData(false);
        })
        .catch(() => {
          setLoadingAnalysisData(false);
        });
    }
    setLoadingAnalysisData(false);
  };

  const getTrainingPlan = async () => {
    setTrainingLoading(true);
    await api.get(`/training_plans?userId=${user.id}&association=1`)
      .then((response) => {
        if (response.data.length > 0) {
          const currentPlan = response.data.filter((plan: any) => isWithinInterval(
            new Date(currentActivity?.start_date || ''),
            { start: new Date(plan.start_date), end: new Date(plan.end_date) },
          ));
          if (currentPlan.length > 0) {
            const dayAfter = addDays(new Date(currentActivity?.start_date || ''), 1);
            const dayBefore = subDays(new Date(currentActivity?.start_date || ''), 1);
            const trainings: any[] = [];
            currentPlan[0].trainingPlanWeeks.map((week: any) => {
              week.trainingPlanDays.map((day: any) => {
                if (isWithinInterval(new Date(day.date), { start: dayBefore, end: dayAfter })) {
                  day.trainings.map((dayTraining: any) => {
                    if (dayTraining.type === currentActivity?.type) {
                      trainings.push({
                        label: `${dayTraining.name} - ${format(new Date(day.date), 'dd/MM')}`,
                        value: dayTraining.id,
                      });
                    }
                    return null;
                  });
                }
                return null;
              });
              return null;
            });
            setTrainingOptions(trainings);
            setTrainingLoading(false);
          }
        }
      })
      .catch(() => {
        setTrainingLoading(false);
      });
    setTrainingLoading(false);
  };

  const loadTrainingPlans = async () => {
    if (currentActivity?.training_id) {
      setTrainingLoading(true);
      await api.get(`/trainings/${currentActivity?.training_id}`)
        .then((response) => {
          setTraining(response.data);
          setTrainingLoading(false);
        })
        .catch(() => {
          setTrainingLoading(false);
        });
    }
  };

  const updateActivity = async () => {
    const updatedActivity = {
      ...currentActivity,
      training_id: trainingToLink,
      start_date: format(new Date(currentActivity?.start_date || ''), 'yyyy-MM-dd HH:mm:ss'),
    };
    await api.put(`user_activities/${currentActivity?.id}`, updatedActivity)
      .then((response) => {
        setCurrentActivity(response.data);
        refresh();
      })
      .catch(() => {
        toast.error('Erro ao vincular a atividade, tente novamente.');
      });
  };

  const updateTrainingStatus = async (trainingToUpdate: any) => {
    if (trainingToUpdate) {
      trainingToUpdate.due_date = format(new Date(trainingToUpdate.due_date), 'yyyy-MM-dd HH:mm:ss');
      await api.put(`/trainings/${trainingToUpdate.id}`, trainingToUpdate)
        .then((response) => {
          setTraining(response.data);
        });
    }
  };

  useEffect(() => {
    if (training && !training.executed_status && currentActivity?.training_id) {
      if (currentActivity?.type === 'Run') {
        if (currentActivity?.distance && training.distance) {
          const rate = training.distance * 0.2;
          if (currentActivity?.distance > parseInt(training.distance, 10) + rate) {
            const updatedTraining = { ...training, executed_status: 'EXECUTED_OVER' };
            updateTrainingStatus(updatedTraining);
          } else if (currentActivity?.distance > parseInt(training.distance, 10) - rate) {
            const updatedTraining = { ...training, executed_status: 'EXECUTED_HIGH' };
            updateTrainingStatus(updatedTraining);
          } else {
            const updatedTraining = { ...training, executed_status: 'EXECUTED_LOW' };
            updateTrainingStatus(updatedTraining);
          }
        } else if (currentActivity?.duration && training.duration) {
          const rate = training.duration * 0.2;

          if (parseFloat(currentActivity?.duration as string) > parseInt(training.duration, 10) + rate) {
            const updatedTraining = { ...training, executed_status: 'EXECUTED_OVER' };
            updateTrainingStatus(updatedTraining);
          } else if (parseFloat(currentActivity?.duration as string) > parseInt(training.duration, 10) - rate) {
            const updatedTraining = { ...training, executed_status: 'EXECUTED_HIGH' };
            updateTrainingStatus(updatedTraining);
          } else {
            const updatedTraining = { ...training, executed_status: 'EXECUTED_LOW' };
            updateTrainingStatus(updatedTraining);
          }
        }
      } else {
        const updatedTraining = { ...training, executed_status: 'EXECUTED_HIGH' };
        updateTrainingStatus(updatedTraining);
      }
    }
  }, [training]);

  useEffect(() => {
    if (trainingToLink) {
      updateActivity();
    }
  }, [trainingToLink]);

  useEffect(() => {
    if (currentActivity?.summary_polyline) loadCoords();
    getTrainingPlan();
    loadTrainingPlans();
    loadLaps();
  }, []);

  useEffect(() => {
    if (
      !currentActivity?.shoes
      && !currentActivity?.barefoot
      && user?.ask_about_activity_shoes
      && isRun
    ) {
      setTimeout(() => {
        setShowAttachShoesModal(true);
      }, 1000);
    }
  }, []);

  const updateOptions = useCallback(async (payload: any, fn: () => void) => {
    try {
      await api.put(`/users/${user.id}`, payload);

      dispatch(updateProfileProps(payload) as any);

      fn();
    } catch (error) {
      toast.error('Ocorreu um erro ao alterar a opção, tente novamente');
    }
  }, []);

  const handleDismissAttachShoesModal = () => {
    setDeactivateControlKmsModalLoading(true);

    updateOptions(
      {
        ask_about_activity_shoes: false,
      },
      () => {
        setDeactivateControlKmsModalLoading(false);
        setShowDeactivateControlKmsModal(false);
        setShowAttachShoesModal(false);
      },
    );
  };

  const handleAttachShoes = (shoesId: number | string) => {
    const payload: IAttachShoesPayload = {};

    if (shoesId === 'barefoot') {
      payload.barefoot = true;

      api.put(`user_activities/${currentActivity?.id}`, payload)
        .then((response) => {
          toast.success('Atividade atualizada, correu sem tênis');
          refresh(false);
          setCurrentActivity({ ...currentActivity, ...response.data });
        })
        .catch(() => {
          toast.error('Erro ao atualizar a atividade, tente novamente.');
        });
    } else {
      payload.activity_id = currentActivity?.id;

      api2.put(`shoes/${shoesId}/attach_activity`, payload)
        .then((response) => {
          toast.success('Tênis vinculado com sucesso à atividade');
          refresh(false);
          setCurrentActivity({ ...currentActivity, ...response.data });
        })
        .catch((e) => {
          if (e?.response?.data?.message) {
            switch (e.response.data.message) {
              case 'shoes_already_attached_to_this_activity':
                toast.error('Você já vinculou esse tênis a essa atividade');
                break;
              default:
                toast.error('Erro ao vincular tênis');
                break;
            }
          } else {
            toast.error('Erro ao vincular tênis');
          }
        });
    }
  };

  const handleDetachShoes = () => {
    const payload: IAttachShoesPayload = {};

    setDetachShoesModalLoading(true);

    if (currentActivity?.barefoot) {
      payload.barefoot = false;

      api.put(`user_activities/${currentActivity?.id}`, payload)
        .then((response) => {
          setShowDetachShoesModal(false);
          toast.success('Atividade atualizada');
          refresh(false);
          setCurrentActivity({ ...currentActivity, ...response.data });
        })
        .catch(() => {
          toast.error('Erro ao atualizar a atividade, tente novamente.');
        })
        .finally(() => {
          setDetachShoesModalLoading(false);
        });
    } else {
      payload.activity_id = currentActivity?.id;

      api2.put(`shoes/${currentActivity?.shoes?.id}/detach_activity`, payload)
        .then((response) => {
          setShowDetachShoesModal(false);
          toast.success('Tênis desvinculado com sucesso da atividade');
          refresh(false);
          setCurrentActivity({ ...currentActivity, ...response.data });
        })
        .catch(() => {
          toast.error('Erro ao desvincular tênis');
        })
        .finally(() => {
          setDetachShoesModalLoading(false);
        });
    }
  };

  const exportTrackFile = async (fileType: string) => {
    setExportLoading(true);

    api2.get(`user-activity/export_activity/${currentActivity?.id}/${fileType}`)
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));

        const link = document.createElement('a');
        link.href = url;
        link.download = response.headers['content-disposition'].match(/filename="([^"]+)"/)?.[1]!;

        document.body.appendChild(link);

        link.click();

        link.parentNode?.removeChild(link);
      })
      .catch(() => {
        toast.error('Erro ao exportar a atividade, tente novamente.');
      })
      .finally(() => {
        setExportLoading(false);
      });
  };

  return (
    <>
      {!(loadingCoords || loadingAnalysisData || trainingLoading)
        && (
          currentActivity
          && (
            <Container
              ref={scrollViewRef}
              data-testid="main-container"
            >
              <RegisterActivity
                edit
                visible={editModal}
                close={() => setEditModal(false)}
                activity={currentActivity}
                refresh={refresh}
                clearStatesOnClose={false}
              />
              <RemoveActivityModal
                isVisible={removeModal}
                setIsVisible={setRemoveModal}
                activity={currentActivity}
                callback={removeCallback}
              />
              <DisassociateModal
                isVisible={disassociateModal}
                setIsVisible={setDisassociateModal}
                activity={currentActivity}
                callback={disassociateCallback}
              />
              {analysisData?.laps && (
                <AnalysisModal
                  analysisData={analysisData}
                  activity={currentActivity}
                  isVisible={analysisModal}
                  setIsVisible={setAnalysisModal}
                />
              )}

              <AttachShoesModal
                visible={showAttachShoesModal}
                setVisible={setShowAttachShoesModal}
                handleDismiss={() => setShowDeactivateControlKmsModal(true)}
                handleAttachShoes={handleAttachShoes}
              />

              <DetachShoesModal
                visible={showDetachShoesModal}
                setVisible={setShowDetachShoesModal}
                handleDetach={handleDetachShoes}
                loading={detachShoesModalLoading}
                shoes={currentActivity?.shoes!}
              />

              <DeactivateControlKmsModal
                visible={showDeactivateControlKmsModal}
                setVisible={setShowDeactivateControlKmsModal}
                handleDeactivate={handleDismissAttachShoesModal}
                loading={deactivateControlKmsModalLoading}
              />

              <SharingActivityModal
                isVisible={sharingModal}
                setIsVisible={setSharingModal}
                activity={currentActivity}
                mapImage={mapImage}
                laps={analysisData?.laps}
              />
              <BackArrow text="ATIVIDADES" bigger backTo="/activity-list" />
              <ActivityContainer>
                <AvatarContainer>
                  {(!!user.s_photo || !!strava_config?.photo_url)
                    ? <Avatar src={user.s_photo || strava_config?.photo_url} />
                    : (
                      <MdPerson
                        size={40}
                        color="white"
                        style={{ marginRight: 5 }}
                      />
                    )}
                  <UserContainer>
                    <UserName>{user.name}</UserName>
                    <DateText>{date}</DateText>
                  </UserContainer>
                </AvatarContainer>
                <HeaderContainer>
                  <ActivityTitle>{currentActivity?.title}</ActivityTitle>
                  <IconsContainer>
                    <IconButton onClick={() => openSharingModal()} data-testid="share-btn">
                      <MdShare size={25} color="white" />
                    </IconButton>
                    <IconButton onClick={() => setEditModal(true)} data-testid="edit-btn">
                      <MdEdit size={25} color="white" />
                    </IconButton>
                    <IconButton onClick={() => setRemoveModal(true)} data-testid="delete-btn">
                      <FaTrash size={20} color={appTheme.redAlert} />
                    </IconButton>
                  </IconsContainer>
                </HeaderContainer>
                {
                  coords && (
                    (mapIsLoaded && (
                      <MapContainer ref={mapRef}>
                        <GoogleMap
                          mapContainerStyle={{
                            width: '100%',
                            height: '100%',
                          }}
                          center={{
                            lat: center.latitude ? center.latitude : 0,
                            lng: center.longitude ? center.longitude : 0,
                          }}
                          zoom={13}
                          options={{ disableDefaultUI: true, styles: mapStyle }}
                        >
                          <Polyline
                            path={coords.map((coord: { latitude: any; longitude: any; }) => ({ lat: coord.latitude, lng: coord.longitude }))}
                            options={{
                              strokeColor: appTheme.primaryD3,
                              strokeOpacity: 1,
                              strokeWeight: 3,
                            }}
                          />
                        </GoogleMap>
                      </MapContainer>
                    ))
                    || (
                      <ContentLoader
                        speed={2}
                        viewBox="-35 35 570 410"
                        backgroundColor={appTheme.background}
                        foregroundColor={appTheme.cardB3}
                      >
                        <path d="M484.52,64.61H15.65C7.1,64.61.17,71.2.17,79.31V299.82c0,8.12,6.93,14.7,15.48,14.7H484.52c8.55,0,15.48-6.58,15.48-14.7V79.31C500,71.2,493.07,64.61,484.52,64.61Zm-9,204.34c0,11.84-7.14,21.44-15.94,21.44H436.39L359.16,171.52c-7.1-10.92-19.67-11.16-27-.51L258.64,277.94C253.78,285,245.73,286,240,280.2l-79.75-80.62c-6-6.06-14.33-5.7-20,.88L62.34,290.39H40.63c-8.8,0-15.94-9.6-15.94-21.44V110.19c0-11.84,7.14-21.44,15.94-21.44H459.54c8.8,0,15.94,9.6,15.94,21.44Z" />
                        <ellipse cx="120" cy="140" rx="28" ry="28" />
                      </ContentLoader>
                    )
                  )
                }
                <InfoContainer>
                  <Separator />
                  {currentActivity?.distance ? (
                    <>
                      <InfoItem>
                        <InfoText>Distância</InfoText>
                        <InfoText>
                          {`${currentActivity?.distance} km`}
                        </InfoText>
                      </InfoItem>
                      <Separator />
                    </>
                  ) : <></>}
                  {currentActivity?.pace ? (
                    <>
                      <InfoItem>
                        <InfoText>Ritmo</InfoText>
                        <InfoText>
                          {
                            `${formatDuration(currentActivity?.pace, 'pace')} /km`
                          }
                        </InfoText>
                      </InfoItem>
                      <Separator />
                    </>
                  ) : <></>}
                  {currentActivity?.duration ? (
                    <>
                      <InfoItem>
                        <InfoText>Tempo</InfoText>
                        <InfoText>
                          {
                            `${formatDuration(currentActivity?.duration as number, 'time')}`
                          }
                        </InfoText>
                      </InfoItem>
                      <Separator />
                    </>
                  ) : <></>}
                  {
                    isRun
                    && (
                      <>
                        <InfoItem>
                          <InfoText>Tênis</InfoText>
                          {
                            !!currentActivity?.barefoot
                            && !currentActivity?.shoes
                            && (
                              <DetachShoesInfoButton
                                type="button"
                                onClick={() => setShowDetachShoesModal(true)}
                              >
                                <DetachShoesInfoButtonTitle>
                                  Nenhum, corri
                                  {' '}
                                  {getGenre(user?.genre!) === 'female' ? 'descalça' : 'descalço'}
                                </DetachShoesInfoButtonTitle>

                                <MdOutlineClose color={theme.colors.redAlert} size={15} />
                              </DetachShoesInfoButton>
                            )
                          }

                          {
                            !currentActivity?.barefoot
                            && !currentActivity?.shoes
                            && (
                              <AttachShoesInfoButton
                                type="button"
                                onClick={() => setShowAttachShoesModal(true)}
                              >
                                Vincular tênis
                              </AttachShoesInfoButton>
                            )
                          }

                          {
                            !!currentActivity?.shoes
                            && (
                              <DetachShoesInfoButton
                                type="button"
                                onClick={() => setShowDetachShoesModal(true)}
                              >
                                <DetachShoesInfoButtonTitle>
                                  {currentActivity.shoes.shoesBrand?.name}
                                  {' '}
                                  -
                                  {' '}
                                  {currentActivity.shoes.name}
                                </DetachShoesInfoButtonTitle>

                                <MdOutlineClose color={theme.colors.redAlert} size={15} />
                              </DetachShoesInfoButton>
                            )
                          }
                        </InfoItem>
                        <Separator />
                      </>
                    )
                  }
                  {currentActivity?.average_speed
                    ? (
                      <>
                        <InfoItem>
                          <InfoText>Vel. Média</InfoText>
                          <InfoText>
                            {
                              `${currentActivity?.average_speed.toFixed(1)} km/h`
                            }
                          </InfoText>
                        </InfoItem>
                        <Separator />
                      </>
                    ) : <></>}
                  {currentActivity?.max_speed
                    ? (
                      <>
                        <InfoItem>
                          <InfoText>Vel. Máxima</InfoText>
                          <InfoText>
                            {`${currentActivity?.max_speed.toFixed(1)} km/h`}
                          </InfoText>
                        </InfoItem>
                        <Separator />
                      </>
                    ) : <></>}
                  {currentActivity?.average_cadence ? (
                    <>
                      <InfoItem>
                        <InfoText>Cadência Média</InfoText>
                        <InfoText>
                          {`${currentActivity?.average_cadence}`}
                        </InfoText>
                      </InfoItem>
                      <Separator />
                    </>
                  ) : <></>}

                  {currentActivity?.average_heartrate
                    ? (
                      <>
                        <InfoItem>
                          <InfoText>FC Média</InfoText>
                          <InfoText>
                            {`${currentActivity?.average_heartrate} bpm`}
                          </InfoText>
                        </InfoItem>
                        <Separator />
                      </>
                    ) : <></>}
                  {currentActivity?.max_heartrate
                    ? (
                      <>
                        <InfoItem>
                          <InfoText>FC Máxima</InfoText>
                          <InfoText>
                            {`${currentActivity?.max_heartrate} bpm`}
                          </InfoText>
                        </InfoItem>
                        <Separator />
                      </>
                    ) : <></>}
                  {currentActivity?.altimetry
                    ? (
                      <>
                        <InfoItem>
                          <InfoText>Ganho de Elevação</InfoText>
                          <InfoText>
                            {`${currentActivity?.altimetry} m`}
                          </InfoText>
                        </InfoItem>
                        <Separator />
                      </>
                    ) : <></>}
                  {currentActivity?.calorie
                    ? (
                      <>
                        <InfoItem>
                          <InfoText>Calorias</InfoText>
                          <InfoText>
                            {`${currentActivity?.calorie} kcal`}
                          </InfoText>
                        </InfoItem>
                        <Separator />
                      </>
                    ) : <></>}
                  {currentActivity?.comments
                    ? (
                      <>
                        <InfoItem>
                          <InfoText>Observações</InfoText>
                          <InfoText>
                            {`${currentActivity?.comments}`}
                          </InfoText>
                        </InfoItem>
                      </>
                    ) : <></>}
                </InfoContainer>
              </ActivityContainer>

              {
                !exportLoading
                && isRun
                && currentActivity?.platform_type === 'GPS'
                // && permissions?.some((permission) => permission.identifier === 'EXPORT_TRACK_FILE')
                && (
                  <ExportContainer>
                    <ExportButton onClick={() => exportTrackFile('gpx')}>
                      <img src={GPXExportIcon} alt="gpx" />
                      <ExportButtonTitle>BAIXAR GPX</ExportButtonTitle>
                    </ExportButton>

                    <ExportButton onClick={() => exportTrackFile('tcx')}>
                      <img src={TCXExportIcon} alt="tcx" width={17} height={17} />
                      <ExportButtonTitle>BAIXAR TCX</ExportButtonTitle>
                    </ExportButton>
                  </ExportContainer>
                )
              }

              {exportLoading && <Loader color="white" />}

              <FlexContainer>
                {analysisData?.laps && (
                  <AnalysisButton onClick={() => setAnalysisModal(true)}>
                    <AnalysisTextButton>
                      ver análise
                    </AnalysisTextButton>
                  </AnalysisButton>
                )}

                {trainingLoading && <Loader color="white" />}
              </FlexContainer>
              <TrainingContainer>
                {((!training && trainingOption.length > 0)
                  || (training && currentActivity?.training_id))
                  && <TrainingLabel>Treino Planejado</TrainingLabel>}
                {!training && !trainingLoading && trainingOption.length > 0 && (
                  <OutContainer>
                    <AssociationContainer>
                      <DropdownContainer>
                        <AssociationLabel>Nenhum</AssociationLabel>
                        <SelectInput
                          inputValue={trainingOption.find((x) => x.value === trainingToLink)?.label || ''}
                          onChange={(e) => {
                            setTrainingToLink(parseInt(e.target.value, 10));
                          }}
                        >
                          <option disabled value="" hidden>Vincule um treino</option>
                          {trainingOption.map((option: { label: string, value: number }) => (
                            <option value={option.value} key={`${option.value}`}>
                              {option.label}
                            </option>
                          ))}
                        </SelectInput>
                      </DropdownContainer>
                    </AssociationContainer>
                  </OutContainer>
                )}
                {training && currentActivity?.training_id && !trainingLoading && (
                  <TrainingDetailContainer
                    training={training}
                    disassociate={() => setDisassociateModal(true)}
                    scroll={scroll}
                  />
                )}
              </TrainingContainer>
            </Container>
          )
        )}
    </>

  );
}

export default ActivityDetail;
