/* eslint-disable react/destructuring-assignment */
import React, {
  useCallback, useRef, useEffect,
} from 'react';

import Modal from 'react-modal';
import { MdClose } from 'react-icons/md';

import MaskedInput from '../MaskedInput';
import FormInput from '../FormInput';
import SelectInput from '../SelectInput';
import TextAreaInput from '../TextAreaInput';

import {
  RowContainer, HeaderContainer, HeaderText,
  Form, modalStyle, FooterContainer,
  SubmitButton,
} from './styles';

import useRegisterActivityController, { IRegisterActivityControl } from '../../hooks/useRegisterActivityController';

export interface IRegisterActivityProps extends IRegisterActivityControl {
  submit?: () => void;
  mockDate?: Date;
  mockTime?: string;
}

function RegisterActivity(props: IRegisterActivityProps) {
  const {
    loading,
    activityType,
    setActivityType,
    title,
    date,
    setTitle,
    setDate,
    time,
    duration,
    pace,
    avgSpeed,
    setAvgSpeed,
    avgHeartRate,
    setAvgHeartRate,
    maxHeartRate,
    setMaxHeartRate,
    elevation,
    setElevation,
    cadence,
    setCadence,
    calories,
    setCalories,
    comments,
    setComments,
    dateError,
    timeError,
    durationError,
    paceInstanceToString,
    header,
    buttonText,
    types,
    clearAllStates,
    closeModal,
    _setTime,
    send,
    setDistanceAndApplyPace,
    durationInstanceToString,
    setDurationAndApplyPace,
    setPaceAndApplyDistance,
    distanceString,
  } = useRegisterActivityController(props);

  const formRef = useRef<HTMLFormElement>(null);
  Modal.setAppElement('body');

  useEffect(() => {
    if (props.visible) {
      document.body.style.overflowY = 'hidden';
    } else {
      document.body.style.overflowY = 'scroll';
    }
  }, [props.visible]);

  const submitForm = useCallback((formEvent: React.FormEvent<HTMLFormElement>) => {
    formEvent.preventDefault();

    if (props.submit) return props.submit;

    if (formRef.current) {
      const formData = new FormData(formRef.current);
      const formProps = Object.fromEntries(formData);

      return send(formProps as any);
    }

    return null;
  }, [props.submit, send]);

  return (
    <div data-testid="register-activity-container">
      <Modal isOpen={props.visible} style={modalStyle}>
        <HeaderContainer data-testid="register-activity-header">
          <HeaderText>{header}</HeaderText>
          <MdClose onClick={() => closeModal()} />
        </HeaderContainer>
        <Form
          onSubmit={submitForm}
          ref={formRef}
        >
          <RowContainer>
            <SelectInput
              name="type"
              inputValue={activityType}
              label="Tipo"
              onChange={(e) => {
                clearAllStates();
                setActivityType(e.target.value);
              }}
            >
              {types.map((type) => (
                <option value={type.value} key={`${type.value}${type.label}`}>
                  {type.label}
                </option>
              ))}
            </SelectInput>

            <FormInput
              name="title"
              label="Título"
              placeholder="Dê um nome para sua atividade"
              inputValue={title}
              type="text"
              onChange={(e) => setTitle(e.target.value)}
              maxLength={40}
            />
          </RowContainer>

          <RowContainer>
            <FormInput
              name="start_date"
              type="date"
              label="Data"
              inputValue={props.mockDate ? props.mockDate.toDateString() : date.toDateString()}
              onChange={(e) => setDate(new Date(e.target.value))}
              error={dateError}
              errorMessage="Insira uma data"
            />

            <FormInput
              name="start_hour"
              type="time"
              label="Hora"
              inputValue={props.mockTime || time}
              onChange={(e) => _setTime(e.target.value)}
              error={timeError}
              errorMessage="Insira um horário válido"
            />
          </RowContainer>

          <RowContainer>
            <FormInput
              name="duration"
              type="time"
              step="1"
              label="Tempo"
              inputValue={durationInstanceToString(duration) || '01:00:00'}
              onChange={(e) => setDurationAndApplyPace(e.target.value)}
              error={durationError}
              errorMessage="Insira uma duração válida"
            />

            {(activityType !== 'WeightTraining' && activityType !== 'Drills') && (
              <FormInput
                name="distance"
                type="number"
                label="Distância"
                inputValue={distanceString}
                onChange={(e) => {
                  if (e.target.value === '') return setDistanceAndApplyPace('');

                  const value = parseFloat(e.target.value);
                  return setDistanceAndApplyPace(value < 999.999 ? value < 0 ? '' : e.target.value : '999.999');
                }}
                placeholder="0 km"
                step="0.001"
                max={999.999}
                min={0}
              />
            )}

            {(activityType !== 'WeightTraining' && activityType !== 'Drills' && activityType !== 'Ride') && (
              <MaskedInput
                name="pace"
                label="Ritmo"
                inputValue={paceInstanceToString(pace)}
                mask="99:99 /Km"
                onChange={(e) => setPaceAndApplyDistance(e.target.value)}
                placeholder="00:00 /Km"
              />
            )}
          </RowContainer>

          <RowContainer>
            {(activityType !== 'WeightTraining' && activityType !== 'Drills' && activityType !== 'Swim') && (
              <FormInput
                name="average_speed"
                type="number"
                label="Vel. média"
                inputValue={avgSpeed}
                onChange={(e) => {
                  if (Number(e.target.value) <= 999) { setAvgSpeed(e.target.value); }
                }}
                placeholder="0 km/h"
              />
            )}

            <FormInput
              name="average_heartrate"
              type="number"
              label="FC média"
              inputValue={avgHeartRate}
              onChange={(e) => {
                if (Number(e.target.value) <= 999) { setAvgHeartRate(e.target.value); }
              }}
              placeholder="0 BPM"
            />

            <FormInput
              name="max_heartrate"
              type="number"
              label="FC máxima"
              inputValue={maxHeartRate}
              onChange={(e) => {
                if (Number(e.target.value) <= 999) { setMaxHeartRate(e.target.value); }
              }}
              placeholder="0 BPM"
            />
          </RowContainer>

          <RowContainer>
            {(activityType !== 'WeightTraining' && activityType !== 'Drills' && activityType !== 'Swim') && (
              <FormInput
                name="altimetry"
                type="number"
                label="Ganho de elevação"
                inputValue={elevation}
                onChange={(e) => {
                  if (Number(e.target.value) <= 999) { setElevation(e.target.value); }
                }}
                placeholder="Metros"
              />
            )}

            {(activityType !== 'WeightTraining' && activityType !== 'Drills' && activityType !== 'Swim' && activityType !== 'Ride')
            && (
              <FormInput
                name="average_cadence"
                type="number"
                label="Cadência média"
                inputValue={cadence}
                onChange={(e) => {
                  if (Number(e.target.value) <= 999) { setCadence(e.target.value); }
                }}
                placeholder="0 PPM"
              />
            )}

            <FormInput
              name="calorie"
              type="number"
              label="Calorias"
              inputValue={calories}
              onChange={(e) => {
                if (Number(e.target.value) <= 999) { setCalories(e.target.value); }
              }}
              placeholder="Kcal"
            />
          </RowContainer>

          <RowContainer>
            <TextAreaInput
              name="comments"
              label="Observações"
              rows={5}
              inputValue={comments}
              onChange={(e) => setComments(e.target.value)}
              maxLength={400}
            />
          </RowContainer>

          <FooterContainer>
            <SubmitButton
              type="submit"
              value={loading ? 'Enviando...' : buttonText}
            />
          </FooterContainer>
        </Form>
      </Modal>
    </div>
  );
}

export default RegisterActivity;
