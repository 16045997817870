import styled from 'styled-components';
import theme from '../../theme';

export const Container = styled.div`
  position: relative;
  width: 100%;
  background-color: ${theme.colors.primary};
  border-top-right-radius: 15px;
  border-top-left-radius: 15px;
  z-index: 5;
`;

export const HeaderContainer = styled.div`
  width: 70%;
  margin-left: 150px;
  padding: 13px;

  @media (max-width: 667px) {
    margin-left: 100px;
  }
  
`;

export const Header = styled.h3`
  text-transform: uppercase;
  color: ${theme.colors.notifications};
  margin-bottom: 8px;
  margin-top: 8px;
`;

export const Medal = styled.img`
  pointer-events: none;
  position: absolute;
  top: 0;
  left: 0;
  width: 160px;
  height: 180px;

  @media (max-width: 667px) {
    height: 120px;
    width: 100px; 
  }
`;

export const Label = styled.p`
  color: ${theme.colors.text};
  text-transform: uppercase;
  font-size: 11px;
`;

export const Distance = styled.p`
  color: ${theme.colors.title};
  text-transform: uppercase;
  font-size: 16px;
  margin-bottom: 8px;
`;

export const InfoContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

export const EditContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const EditText = styled.span`
  color: white;
  text-decoration: underline;
  text-decoration-color: white;
  font-size: 14px;
  margin-left: 5px;
  cursor: pointer;

  :hover {
    opacity: 0.7;
  }

  @media (max-width: 667px) {
    font-size: 12px;
    width: 70%;
  }
`;
